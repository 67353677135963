import './App.scss';
import {React, useState, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from "react-router-dom";
import Thumbnails from './Thumbnails';

const App =()=> {
   // eslint-disable-next-line
  const [inputText, setInputText] = useState("");
  const [t, i18n] = useTranslation();
  const {lang} = useParams();

  //Initialize language.
  useEffect(()=>{
    i18n.changeLanguage(lang);
    // eslint-disable-next-line
  },[])

  //translate the input word to lowercase
  const searchHandler = (e) =>{
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  }

  return (
    <div className="html">
      <p className="headline">
        {t("Laboratory tool illustrations for your SOP and documents")}
      </p>
      <div>
        <input
          className="searchBox"
          onChange={searchHandler}
          type="search"
          placeholder={t("Search")}
          list="options"
          />
          <datalist id="options">
            <option value={t("vial")}></option>
            <option value={t("tube")}></option>
            <option value={t("pipette")}></option>
          </datalist>
      </div>
      <Thumbnails searchWord={inputText}/>
    </div>
  );
}

export default App;
