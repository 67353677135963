import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider, Navigate} from "react-router-dom";
import App from './App';
import Header from './Header';
import About from './About';
import License from './License'; 
import Contact from './Contact';
import {CookiesProvider} from "react-cookie";
import "./i18n";

const router = createBrowserRouter([
  {
    path: "/contact/:lang",
    element: (<><Header/><Contact/></>),
  },
  {
    path: "/about/:lang",
    element: (<><Header/><About /></>),
  },
  {
    path: "/license/:lang",
    element: (<><Header /><License /></>)
  },
  {
    path:"/top/:lang",
    element: (<><Header/><App/></>)
  },
  {
    path:"/*",
    element: <Navigate to="/top/en" />
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <CookiesProvider>
      <RouterProvider router={router} />
    </CookiesProvider>
  </React.StrictMode>
);