import './Header.scss';
import React ,{useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams } from "react-router-dom";
//import {useCookies} from "react-cookie";
import logo from './LabDraw_logo.png';

const Header =()=> {
  const [t, i18n] = useTranslation();
  const {lang} = useParams();


  //linked urls with language slug
  let topUrl = "/top/"+lang;
  let aboutUrl = "/about/"+lang;
  let licenseUrl = "/license/"+lang;
  let contactUrl = "/contact/"+lang;

  //For handling cookies
  // eslint-disable-next-line
  //const [cookies, setCookie, removeCookie] = useCookies();

  // for keeping the language setting
  useEffect (()=>{
    if (lang === "en") {
      i18n.changeLanguage ("en");
      //setCookie("lang", "en");
    } else if (lang === "ja"){
      i18n.changeLanguage ("ja");
      //setCookie("lang", "ja");
    } else {
      i18n.changeLanguage ("en");
      //setCookie("lang", "en");
    }
  // eslint-disable-next-line
  },[])

  return (
    <div className="html">
      <div className="header">
        <div className="title">
          <a href={topUrl}><img alt="logo" className="titleLogo" src={logo} /></a>
          <div className="titleChars">
            Lab
            <br/>
            Draw
            <br/>
            Me
          </div>
          <div className="langSwitchButton">
            <a href="/top/en"><button>English</button></a>
            <a href="/top/ja"><button>日本語</button></a>
          </div>
          <div className="toggleButtonContainer">
            <input id="hiddenToggleButton" className="hiddenToggleButton" type="checkbox"/>
            <label className="hamburgerLines" htmlFor="hiddenToggleButton">
              <span className="line1"></span>
              <span className="line2"></span>
              <span className="line3"></span>
            </label>
            <ul className="menuList">
              <a href={topUrl} style={{textDecoration:"none", color:"white"}}><li className={i18n.language==="en"?"liEn":"liJa"}>{t('Top')}</li></a>
              <a href={aboutUrl} style={{textDecoration:"none", color:"white"}}><li className={i18n.language==="en"?"liEn":"liJa"}>{t('About')}</li></a>
              <a href={licenseUrl} style={{textDecoration:"none", color:"white"}}><li className={i18n.language==="en"?"liEn":"liJa"}>{t('License')}</li></a>
              <a href={contactUrl} style={{textDecoration:"none", color:"white"}}><li className={i18n.language==="en"?"liEn":"liJa"}>{t('Contact')}</li></a>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
